@use '~styles/variables' as *;

.root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px 80px;
}

.stepper {
  display: flex;
  align-items: center;
  justify-content: center;

  .step {
    height: 1px;
    width: 10px;
    background: rgba($color-romance, 0.3);

    &Active {
      background: $color-romance;
    }

    &:not(:first-child) {
      margin-left: 22px;
    }
  }
}

.content {
  margin-top: 100px;
}

.close {
  display: flex;
  justify-content: flex-end;
}

.form {
  position: relative;
}

.customTips {
  position: absolute;
  left: calc(50% - 50px);
  top: 50px;
  z-index: 10;

  &Select {
    display: flex;

    & > div:not(:first-child) {
      margin-left: 10px;
    }
  }
}

.btn {
  height: 52px;
  width: 52px;
  border: thin solid $color-romance;
  border-radius: 100%;
  font-size: 14px;
  text-align: center;
  transition: 0.25s;

  &Active {
    background: $color-romance;
    color: black;
  }
}

.total {
  width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &Price {
    display: flex;
    flex-direction: column;

    &Text {
      font-size: 20px;
      color: $color-romance;
    }
  }
}

.textOpacity {
  opacity: 0.5;
}
